
import { Vue, Options } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import { ManualDeductAddSaldoController } from "@/app/ui/controllers/ManualDeductAddSaldoController";
import { dateToDateString, dateToTime, formatPriceRM, formatPriceRP } from "@/app/infrastructures/misc/Utils";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import Cookies from "js-cookie";

@Options({
  components: {
    DetailLayout,
    Title
  }
})
export default class Detail extends Vue {
  mounted() {
    this.fetchDetail();
  }

  fetchDetail() {
    ManualDeductAddSaldoController.fetchDetailManualDeductAddSaldo(
      Number(this.id)
    );
  }

  // Format Date
  formatDate(date: string) {
    return `${dateToDateString(date)}, ${dateToTime(date)} WIB`;
  }

  get id() {
    return Cookies.get(`${process.env.NODE_ENV}_pg_trx_id`) || "";
  }

  goBack() {
    this.$router.push("/finance/manual-deduct");
  }

  openUrl() {
    if (this.detail.transferProof) window.open(this.detail.transferProof);
  }
  get isLoadingDetail() {
    return ManualDeductAddSaldoController.isLoadingDetail;
  }

  get transferProof() {
    const findIndex = this.detail.transferProof.indexOf("files");
    return this.detail.transferProof.charAt(findIndex + 5) === "/"
      ? this.detail.transferProof.slice(
          findIndex + 6,
          this.detail.transferProof.length
        )
      : "";
  }

  get errorCause() {
    return ManualDeductAddSaldoController.errorCause;
  }

  get isErrorDetail() {
    return ManualDeductAddSaldoController.isErrorDetail;
  }

  get detail() {
    return ManualDeductAddSaldoController.detailManualDeductAddSaldo;
  }

  get amountSaldo() {
    return this.detail.currency === "MYR" ? formatPriceRM(this.detail.amountSaldo) : formatPriceRP(this.detail.amountSaldo);
  }
}
