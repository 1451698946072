import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ml-4" }
const _hoisted_2 = { class: "flex gap-y-6 flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_DetailLayout = _resolveComponent("DetailLayout")!

  return (_openBlock(), _createBlock(_component_DetailLayout, {
    customClass: "px-0",
    loading: _ctx.isLoadingDetail,
    error: _ctx.isErrorDetail,
    errorType: _ctx.errorCause,
    onTryAgain: _ctx.fetchDetail,
    title: _ctx.detail.genesisTransactionId,
    onBack: _ctx.goBack
  }, {
    data: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_Title, { label: "Detail Pengiriman" }),
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_DataWrapper, {
            class: "w-1/2",
            label: "Genesis Transaksi ID",
            value: _ctx.detail.genesisTransactionId
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-1/2",
            label: "Tipe Akun",
            customStyleValue: "capitalize",
            value: _ctx.detail.accountType
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-1/2",
            label: "Nama Client/Partner",
            value: _ctx.detail.clientPartnerName
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-1/2",
            label: "Tipe Transaksi",
            value: _ctx.detail.type
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-1/2",
            label: "Aktivitas",
            customStyleValue: "capitalize",
            value: _ctx.detail.activityName
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-1/2",
            label: "Jumlah",
            value: _ctx.amountSaldo
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-1/3",
            label: "Bukti Transaksi",
            onClick: _ctx.openUrl,
            customStyleValue: "text-left text-red-lp-100 underline cursor-pointer",
            value: _ctx.transferProof
          }, null, 8, ["onClick", "value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-3/4",
            label: "Catatan",
            value: _ctx.detail.notes
          }, null, 8, ["value"])
        ])
      ])
    ]),
    "sub-data": _withCtx(() => [
      _createVNode(_component_DataWrapper, {
        class: "w-1/2",
        label: "Tanggal Dibuat",
        value: _ctx.formatDate(_ctx.detail.createdAt),
        caption: _ctx.detail.createdBy
      }, null, 8, ["value", "caption"])
    ]),
    _: 1
  }, 8, ["loading", "error", "errorType", "onTryAgain", "title", "onBack"]))
}