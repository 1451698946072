
/* eslint-disable @typescript-eslint/camelcase */
import { Vue, Options } from "vue-class-component";
import {
  checkRolePermission,
  ellipsisFileName,
  ellipsisString,
  formatPriceRP,
  formatPriceRM,
  formatDateWithoutTime
} from "@/app/infrastructures/misc/Utils";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { FINANCE_MANUAL_DEDUCT_ADD_SALDO } from "@/app/infrastructures/misc/RolePermission";
import { ManualDeductAddSaldoData } from "@/domain/entities/ManualDeductAddSaldo";
import FilterListManualDeductAdd from "@/app/ui/views/finance/manual-deduct-add-saldo/filter-list-manual-deduct-add.vue";
import { ManualDeductAddSaldoController } from "@/app/ui/controllers/ManualDeductAddSaldoController";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import router from "../../../router";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import ModalDownload from "@/app/ui/views/finance/manual-deduct-add-saldo/components/modal-download.vue";
import Cookies from "js-cookie";

@Options({
  components: {
    FilterListManualDeductAdd,
    EmptyState,
    Tooltip,
    ModalDownload
  },
  computed: {
    getTitle() {
      return this.$route.meta.name;
    },
    ableToCreate() {
      return checkRolePermission(FINANCE_MANUAL_DEDUCT_ADD_SALDO.CREATE);
    },
    ableToViewDetail() {
      return checkRolePermission(FINANCE_MANUAL_DEDUCT_ADD_SALDO.DETAIL);
    }
  },
  directives: {
    "click-outside": {
      beforeMount: function(el, binding) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class ManualDeductAddSaldo extends Vue {
  fetchManualDeductAddSaldoList() {
    ManualDeductAddSaldoController.getManualDeductAddSaldoList();
  }

  get manualDeductAddSaldoList() {
    return ManualDeductAddSaldoController.manualDeductAddSaldoList.data;
  }

  get isLoading() {
    return ManualDeductAddSaldoController.isLoading;
  }

  get errorCause() {
    return ManualDeductAddSaldoController.errorCause;
  }

  goToCreate() {
    router.push("/finance/manual-deduct/create");
  }

  get columns() {
    return [
      {
        name: "Genesis Transaksi ID",
        styleHead: "w-300px text-left align-top",
        render: (item: ManualDeductAddSaldoData) => {
          return `<div class="overflow-ellipsis text-left">${item.genesisTransactionId}</div>`;
        }
      },
      {
        name: "Nama Client/Partner",
        styleHead: "w-64 text-left whitespace-no-wrap align-top",
        render: (item: ManualDeductAddSaldoData) => {
          return `<div class="overflow-ellipsis text-left">${item.clientPartnerName}</div>`;
        }
      },
      {
        name: "Tipe Transaksi",
        styleHead: "w-64 text-left whitespace-no-wrap align-top",
        render: (item: ManualDeductAddSaldoData) => {
          return `<div class="overflow-ellipsis text-left">${item.type}</div>`;
        }
      },
      {
        name: "Aktivitas",
        styleHead: "w-100px text-left whitespace-no-wrap align-top",
        render: (item: ManualDeductAddSaldoData) => {
          return `<div class="capitalize overflow-ellipsis text-left">${item.activityName}</div>`;
        }
      },
      {
        name: "Jumlah",
        styleHead: "w-200px text-left whitespace-no-wrap align-top",
        render: (item: ManualDeductAddSaldoData) => {
          return `<div class="capitalize overflow-ellipsis text-left flex flex-row gap-x-1 font-medium ${
            item.activityName.toLowerCase() === "credit"
              ? "text-green-lp-100"
              : "text-red-lp-750"
          }">${item.currency === 'MYR' ? formatPriceRM(item.amountSaldo) : formatPriceRP(item.amountSaldo)}</div>`;
        }
      },
      {
        name: "Tanggal Transaksi",
        styleHead: "w-40 text-left whitespace-no-wrap align-top",
        render: (item: ManualDeductAddSaldoData) => {
          return `<div class="flex flex-col"><div class="overflow-ellipsis text-left">${formatDateWithoutTime(
            item.createdAt
          )}</div>
          <div class="truncate text-gray-lp-600 w-32">${
            item.createdBy
          }</div></div>`;
        }
      },
      {
        name: "Status",
        styleHead: "w-36 text-left whitespace-no-wrap align-top",
        render: (item: ManualDeductAddSaldoData) => {
          return `<div class="capitalize overflow-ellipsis text-left flex flex-row gap-x-1 font-medium ${this.convertColor(item.status.toLowerCase())}">
              <img class="w-3 max-w-none" src="${this.convertStatusIcon(item.status.toLowerCase())}" />
              ${item.status}
            </div>`;
        }
      },
      {
        name: "Bukti Transfer",
        styleHead: "w-64 text-left whitespace-no-wrap align-top",
        render: (item: ManualDeductAddSaldoData) => {
          return `<div class="flex flex-row"><div class="overflow-ellipsis text-left text-red-lp-100 underline">${ellipsisFileName(
            item.transferProof
          )}</div></div>`;
        }
      },
      {
        name: "Catatan",
        styleHead: "w-300px text-left whitespace-no-wrap align-top",
        render: (item: ManualDeductAddSaldoData) => {
          return `<div class="overflow-ellipsis text-left">${ellipsisString(
            item.notes,
            66
          )}</div>`;
        }
      }
    ];
  }

  convertStatusIcon(status: string) {
    return require(`@/app/ui/assets/svg/${status}-status.svg`)
  }

  convertColor(status: string) {
    const statuses: any = {
      "success": "text-green-lp-100",
      "failed": "text-red-lp-750",
      "pending": "text-yellow-lp-100"
    }

    return statuses[status];
  }

  get isCollapseSidebar() {
    return AccountController.isCollapseSidebar;
  }

  isAdvancedFilterOpened = false;
  onOpenedAdvancedFilter(event: any) {
    this.isAdvancedFilterOpened = event;
  }

  listType = [
    {
      name: "Rekonsiliasi Pengurangan Saldo",
      value: "stt_deduct_adjustment_saldo"
    },
    { name: "Rekonsiliasi Penambahan Saldo", value: "stt_add_adjustment_saldo" }
  ];

  get pagination() {
    return ManualDeductAddSaldoController.manualDeductAddSaldoList.pagination;
  }

  onClickRow(item: ManualDeductAddSaldoData) {
    Cookies.set(`${process.env.NODE_ENV}_pg_trx_id`, item.id.toString());
    router.push(
      `/finance/manual-deduct/${encodeURIComponent(item.genesisTransactionId)}`
    );
  }

  // time range picker
  minDate: any = null;
  maxDate: any = null;
  tempStartDate: any = null;
  tempEndDate: any = null;
  isStartDate = true;
  setDay(day: any) {
    if (this.isStartDate) {
      this.tempStartDate = this.startDate;
      this.tempEndDate = this.endDate;
      ManualDeductAddSaldoController.setPeriodeStart(null);
      ManualDeductAddSaldoController.setPeriodeEnd(null);
      this.minDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() - 89)
      );
      this.maxDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() + 89)
      );
      this.isStartDate = false;
    } else {
      this.endDate && (this.isStartDate = true);
    }
  }

  cancelRangePick() {
    if (!this.isStartDate) {
      this.minDate = null;
      this.maxDate = null;
      this.isStartDate = true;
      ManualDeductAddSaldoController.setPeriodeStart(this.tempStartDate);
      ManualDeductAddSaldoController.setPeriodeEnd(this.tempEndDate);
      this.tempStartDate = null;
      this.tempEndDate = null;
    }
  }

  get startDate() {
    return ManualDeductAddSaldoController.periodeStart;
  }

  get endDate() {
    return ManualDeductAddSaldoController.periodeEnd;
  }

  setDateRange(value: Array<any>) {
    this.minDate = null;
    this.maxDate = null;
    ManualDeductAddSaldoController.setPeriodeStart(value[0]);
    ManualDeductAddSaldoController.setPeriodeEnd(value[1]);
    value[0] && value[1] && this.fetchManualDeductAddSaldoList();
  }

  get isOpenModalDownload() {
    return ManualDeductAddSaldoController.isOpenModalDownload;
  }

  openModalDownload() {
    ManualDeductAddSaldoController.setOpenModalDownload(true);
  }

  onCloseModalDownload() {
    return ManualDeductAddSaldoController.setOpenModalDownload(false);
  }
}
