
/* eslint-disable @typescript-eslint/camelcase */
import { Options, Vue } from "vue-class-component";
import SelectSearchV2 from "@/app/ui/components/select-search/index.vue";
import Checkbox from "primevue/checkbox";
import CustomDropdown from "@/app/ui/components/custom-dropdown/index.vue";
import RadioButton from "primevue/radiobutton";
import { ClientController } from "@/app/ui/controllers/ClientController";
import debounce from "lodash/debounce";
import { ManualDeductAddSaldoController } from "@/app/ui/controllers/ManualDeductAddSaldoController";

@Options({
  emits: ["opened"],
  components: {
    SelectSearchV2,
    Checkbox,
    CustomDropdown,
    RadioButton
  },
  props: {
    isDisabled: {
      default: false,
      type: Boolean
    },
    isScroll: {
      default: true,
      type: Boolean
    }
  },
  directives: {
    "click-outside": {
      beforeMount: function(el, binding) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class FilterListManualDeductAdd extends Vue {
  mounted() {
    this.fetchCreatedBy("");
  }
  isChevron = false;
  props: any = this.$props;
  openFilter = false;
  fetchManualDeductAddSaldoList() {
    ManualDeductAddSaldoController.setFirstPage();
    ManualDeductAddSaldoController.getManualDeductAddSaldoList();
  }
  onChange = debounce(() => {
    this.fetchManualDeductAddSaldoList();
  }, 500);
  resetFilter = debounce(() => {
    ManualDeductAddSaldoController.setAdvanceFilterData({
      clientPartnerType: "",
      clientPartner: "",
      createdBy: "",
      type: ""
    });
  }, 0);
  get advanceFilter() {
    return ManualDeductAddSaldoController.advanceFilterData;
  }
  get sumFilter() {
    this.onChange();
    return (
      (this.advanceFilter.type ? 1 : 0) +
      (this.advanceFilter.clientPartner ? 1 : 0) +
      (this.advanceFilter.createdBy ? 1 : 0)
    );
  }

  //radio
  get isClientPartnerTypeDisabled() {
    return (
      this.advanceFilter.type === "stt_deduct_adjustment_saldo_cod" ||
      this.advanceFilter.type === "stt_add_adjustment_saldo_cod" ||
      this.advanceFilter.type === ""
    );
  }
  onChangeClientPartnerType(value: string) {
    if (value !== this.advanceFilter.clientPartnerType) {
      this.advanceFilter.clientPartner = "";
      this.fetchClientPartner("");
    }
    this.advanceFilter.clientPartnerType = value;
  }

  // client/partner
  fetchClientPartner = debounce((search: string) => {
    ClientController._onGetListPartner({
      page: 1,
      limit: 50,
      search: search,
      partnerType:
        this.advanceFilter.clientPartnerType === "partner" ? "pos" : "",
      type:
        this.advanceFilter.clientPartnerType === "partner"
          ? "partner"
          : "client",
      isParent: this.advanceFilter.clientPartnerType != "partner"
    });
  }, 250);
  get loadingClientPartner() {
    return ClientController.isLoading;
  }
  get partnerClientData() {
    return ClientController.clientPartnerData.map(e => ({
      id: e.id,
      name: `${e.name}`,
      code: e.code
    }));
  }

  // transaction type
  transactionTypeList = [
    {
      name: "Pilih",
      value: ""
    },
    {
      name: "Rekonsiliasi Pengurangan Saldo",
      value: "stt_deduct_adjustment_saldo"
    },
    {
      name: "Rekonsiliasi Penambahan Saldo",
      value: "stt_add_adjustment_saldo"
    },
    {
      name: "Rekonsiliasi Pengurangan Penghasilan COD",
      value: "stt_deduct_adjustment_saldo_cod"
    },
    {
      name: "Rekonsiliasi Penambahan Penghasilan COD",
      value: "stt_add_adjustment_saldo_cod"
    }
  ];
  isOpenTransactionType = false;
  onOpenSelectTransactionType() {
    this.isOpenTransactionType = true;
  }
  onCloseSelectTransactionType() {
    this.isOpenTransactionType = false;
  }
  transactionTypeName: any = "";
  onSelectTransactionType(key: string, value: string) {
    const theType: any = this.transactionTypeList.find(
      (item: any) => item.value === value
    );
    this.transactionTypeName = theType.name;
    this.onCloseSelectTransactionType();
    this.advanceFilter.type = value;
    if (
      value === "stt_deduct_adjustment_saldo_cod" ||
      value === "stt_add_adjustment_saldo_cod"
    ) {
      this.onChangeClientPartnerType("client");
    }
  }

  // created by
  fetchCreatedBy = debounce((search: string) => {
    return ClientController.getListOfActor(search);
  }, 250);
  get listCreatedBy() {
    return ClientController.actors;
  }
  get isLoadingCreatedBy() {
    return ClientController.isLoadingActor;
  }
}
