
import { PropType } from "vue";
import { Vue, Options } from "vue-class-component";
import { ClientController } from "@/app/ui/controllers/ClientController";
import { ManualDeductAddSaldoController } from "@/app/ui/controllers/ManualDeductAddSaldoController";
import debounce from "lodash/debounce";
import { PosController } from "@/app/ui/controllers/PosController";

@Options({
  components: {},
  props: {
    onClose: {
      default: Function as PropType<() => void>,
      type: Function
    }
  }
})
export default class ModalDownload extends Vue {
  mounted() {
    this.onSearchClientName("");
    this.fetchActors("");
  }

  fetchActors = debounce((search: string) => {
    return ClientController.getListOfActor(search);
  }, 250);

  get listActor() {
    return ClientController.actors;
  }

  get isLoadingActors() {
    return ClientController.isLoadingActor;
  }
  // Modal Success after Save
  get openModalSuccess() {
    return ManualDeductAddSaldoController.isOpenModalSuccess;
  }
  onCloseModalSuccess() {
    ManualDeductAddSaldoController.setOpenModalSuccess(false);
    ManualDeductAddSaldoController.setOpenModalDownload(false);
  }

  get isDisabled() {
    return !this.typeName || !this.clientNameDisplay || !this.createdByName;
  }

  // Client Name
  clientNameDisplay = "";
  clientNameValue = 0;
  onSearchClientName = debounce((value: string) => {
    ClientController._onGetListPartner({
      page: 1,
      limit: 10,
      search: value,
      partnerType: this.typeName === "partner" ? "pos" : "",
      type: this.typeName,
      isBranch: false,
      isParent: this.typeName !== "partner"
    });
  }, 250);

  get clientNameList() {
    const tmp: Array<any> = [{ id: 0, name: "ALL", code: "ALL" }];
    return [...tmp, ...ClientController.clientPartnerData];
  }

  get isLoadingPartnerClient() {
    return PosController.isLoading;
  }

  // Account type
  typeName = "";
  typeData = [
    { name: "Pilih Client/Partner", value: "" },
    { name: "Client", value: "client" },
    { name: "Partner", value: "partner" }
  ];
  get isAccountTypeDisabled() {
    return (
      this.transactionTypeName === "stt_deduct_adjustment_saldo_cod" ||
      this.transactionTypeName === "stt_add_adjustment_saldo_cod"
    );
  }

  typeSelect = false;

  onOpenTypeSelect() {
    this.typeSelect = true;
  }

  onCloseTypeSelect() {
    this.typeSelect = false;
  }
  onSelectType(_: string, value: string) {
    ClientController.setClientPartnerData([]);
    this.clientNameDisplay = "";
    this.typeName = value;
    this.onCloseTypeSelect();
    this.onSearchClientName("");
  }

  // tipe transaksi
  transactionTypeName = "";
  transactionTypeData = [
    { name: "ALL", value: "" },
    {
      name: "Rekonsiliasi Pengurangan saldo",
      value: "stt_deduct_adjustment_saldo"
    },
    {
      name: "Rekonsiliasi Penambahan saldo",
      value: "stt_add_adjustment_saldo"
    },
    {
      name: "Rekonsiliasi Pengurangan Penghasilan COD",
      value: "stt_deduct_adjustment_saldo_cod"
    },
    {
      name: "Rekonsiliasi Penambahan Penghasilan COD",
      value: "stt_add_adjustment_saldo_cod"
    }
  ];

  transactionTypeSelect = false;

  onOpenTransactionTypeSelect() {
    this.transactionTypeSelect = true;
  }

  onCloseTransactionTypeSelect() {
    this.transactionTypeSelect = false;
  }
  onSelectTransactionType(_: string, value: string) {
    this.transactionTypeName = value;
    if (
      value === "stt_deduct_adjustment_saldo_cod" ||
      value === "stt_add_adjustment_saldo_cod"
    ) {
      this.onSelectType(this.typeData[1].name, this.typeData[1].value);
    }
    this.onCloseTransactionTypeSelect();
  }

  // created by
  createdByName: any = "";

  // time range picker
  minDate: any = null;
  maxDate: any = null;
  tempStartDate: any = null;
  tempEndDate: any = null;
  isStartDate = true;
  setDay(day: any) {
    if (this.isStartDate) {
      this.tempStartDate = this.startDate;
      this.tempEndDate = this.endDate;
      ManualDeductAddSaldoController.setPeriodeStart(null);
      ManualDeductAddSaldoController.setPeriodeEnd(null);
      this.minDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() - 89)
      );
      this.maxDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() + 89)
      );
      this.isStartDate = false;
    } else {
      this.endDate && (this.isStartDate = true);
    }
  }

  cancelRangePick() {
    if (!this.isStartDate) {
      this.minDate = null;
      this.maxDate = null;
      this.isStartDate = true;
      ManualDeductAddSaldoController.setPeriodeStart(this.tempStartDate);
      ManualDeductAddSaldoController.setPeriodeEnd(this.tempEndDate);
      this.tempStartDate = null;
      this.tempEndDate = null;
    }
  }

  get startDate() {
    return ManualDeductAddSaldoController.periodeStart;
  }

  get endDate() {
    return ManualDeductAddSaldoController.periodeEnd;
  }

  setDateRange(value: Array<any>) {
    this.minDate = null;
    this.maxDate = null;
    ManualDeductAddSaldoController.setPeriodeStart(value[0]);
    ManualDeductAddSaldoController.setPeriodeEnd(value[1]);
  }

  onDownloadReport() {
    const actor: any = this.clientNameDisplay;
    ManualDeductAddSaldoController.getDeductReports({
      adjustmentType: this.transactionTypeName,
      actorType: this.typeName === "partner" ? "pos" : this.typeName,
      actorId: actor.id,
      startDate: this.startDate
        ? new Date(this.startDate).toLocaleDateString("fr-CA")
        : "",
      endDate: this.endDate
        ? new Date(this.endDate).toLocaleDateString("fr-CA")
        : "",
      createdBy: Number(this.createdByName.createdId)
    });
  }
}
