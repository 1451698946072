import { vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  id: "pickup-manifest",
  class: "relative bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen w-full"
}
const _hoisted_2 = { class: "flex flex-col justify-between items-start border-b border-gray-lp-400 z-20 relative pt-8" }
const _hoisted_3 = { class: "flex flex-row py-6 align-middle items-center justify-between w-full" }
const _hoisted_4 = { class: "text-20px text-black-lp-200 flex flex-row items-center flex-no-wrap relative" }
const _hoisted_5 = {
  class: "mr-8 font-semibold",
  style: {"align-self":"center"}
}
const _hoisted_6 = { class: "flex items-center" }
const _hoisted_7 = { class: "mx-4 relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_filter_list_manual_deduct_add = _resolveComponent("filter-list-manual-deduct-add")!
  const _component_time_range_picker = _resolveComponent("time-range-picker")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_modal_download = _resolveComponent("modal-download")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _withDirectives(_createVNode("div", {
          class: "fixed right-0 h-full z-20",
          style: 
          `background-color:rgba(0,0,0,0.3); top: 9rem; width: calc(100% - ${
            _ctx.isAdvancedFilterOpened && _ctx.isCollapseSidebar ? `60px` : `264px`
          });`
        
        }, null, 4), [
          [_vShow, _ctx.isAdvancedFilterOpened]
        ]),
        _createVNode(_component_Breadcrumb),
        _createVNode("div", _hoisted_3, [
          _createVNode("div", _hoisted_4, [
            _createVNode("span", _hoisted_5, _toDisplayString(_ctx.getTitle), 1),
            _createVNode(_component_filter_list_manual_deduct_add, {
              isScroll: true,
              isDisabled: false,
              onOpened: _ctx.onOpenedAdvancedFilter
            }, null, 8, ["onOpened"]),
            _createVNode(_component_time_range_picker, {
              default: "7 hari terakhir",
              class: "mr-4",
              startDate: _ctx.startDate,
              endDate: _ctx.endDate,
              onSelect: _ctx.setDateRange,
              style: {"margin-right":"0"},
              minDate: _ctx.minDate,
              maxDate: _ctx.maxDate,
              onDayClick: _ctx.setDay,
              onCancelRangePick: _ctx.cancelRangePick,
              showOptions: "custom, hari ini, 7 hari terakhir, 30 hari terakhir",
              isOptionDisabled: !_ctx.isStartDate
            }, null, 8, ["startDate", "endDate", "onSelect", "minDate", "maxDate", "onDayClick", "onCancelRangePick", "isOptionDisabled"])
          ]),
          _createVNode("div", _hoisted_6, [
            _createVNode("div", _hoisted_7, [
              _createVNode(_component_lp_button, {
                onClick: _ctx.openModalDownload,
                "text-color": "red-lp-300",
                outline: "",
                small: "",
                icon: "document-download-outline"
              }, null, 8, ["onClick"])
            ]),
            (_ctx.ableToCreate)
              ? (_openBlock(), _createBlock(_component_lp_button, {
                  key: 0,
                  onClick: _ctx.goToCreate,
                  title: "Buat Transaksi",
                  small: "",
                  customClass: "px-4",
                  textColor: "white"
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      (_ctx.manualDeductAddSaldoList.length === 0 && !_ctx.isLoading && !_ctx.errorCause)
        ? (_openBlock(), _createBlock(_component_EmptyState, {
            key: 0,
            customMessage: "Oops.. Belum ada data yang dapat ditampilkan"
          }))
        : (_openBlock(), _createBlock(_component_TableV2, {
            key: 1,
            columns: _ctx.columns,
            data: _ctx.manualDeductAddSaldoList,
            loading: _ctx.isLoading,
            onRequest: _ctx.fetchManualDeductAddSaldoList,
            errorCause: _ctx.errorCause,
            isDetailAble: _ctx.ableToViewDetail,
            onClick: _ctx.onClickRow,
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event))
          }, null, 8, ["columns", "data", "loading", "onRequest", "errorCause", "isDetailAble", "onClick", "pagination"]))
    ]),
    (_ctx.isOpenModalDownload)
      ? (_openBlock(), _createBlock(_component_modal_download, {
          key: 0,
          onClose: _ctx.onCloseModalDownload
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}